import React from 'react'
import loadable from '@loadable/component'

const Shopping = loadable(() => import('./Shopping'))

export default props => (
	<div className="shopping-async">
		<Shopping {...props} />
	</div>
)
